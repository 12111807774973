<template>
  <transition name="slide-down">
    <v-row
      class="reader-top px-0 ma-0"
      :style="{
        height: barHeight,
        paddingTop: statusBarHeight,
        background: barBackground,
      }"
      v-show="showBar"
    >
      <v-col class="pa-0 ma-0" cols="auto">
        <v-row
          class="reader-top-back pa-0 ma-0"
          align="center"
          justify="center"
          @click="back"
        >
          <v-icon class="primary--text" size="20">icon-return</v-icon>
        </v-row>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
export default {
  name: "reader-top-bar",
  props: {
    showBar: {
      type: Boolean,
      default: false,
    },
    barBackground: {
      type: String,
      default: "",
    },
    isPC: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusBarHeight: "0px",
      barHeight: "0px",
    };
  },
  components: {},
  computed: {},
  methods: {
    back() {
      if (this.isPC) {
        this.$router.go(-1);
      } else {
        this.$emit("back");
      }
    },
  },
  watch: {},
  created() {
    if (this.isPC) {
      this.statusBarHeight = "0px";
      this.barHeight = "58px";
    } else {
      this.$native.getStatusBarHeight().then((height) => {
        this.statusBarHeight = height + "px";
        this.barHeight = parseInt(height) + 58 + "px";
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/anim";
.reader-top {
  position: absolute;
  width: 100%;
  height: 58px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
  z-index: 101;
  .reader-top-back {
    width: 68px;
    height: 58px;
  }
}
</style>
