<template>
  <transition name="slide-right" @after-enter="transitionComplete">
    <div class="content">
      <reader-drawer-catalog
        v-if="getBookAvailable()"
        v-show="tabIndex == 0"
        :bookInfo="bookInfo"
        :bookId="bookId"
        @showTeacherSay="showTeacherSay"
        @jumpTo="jumpTo"
      ></reader-drawer-catalog>

      <reader-drawer-note
        v-if="getBookAvailable()"
        v-show="tabIndex == 1"
      ></reader-drawer-note>

      <div class="empty" v-else>Loading...</div>

      <v-row class="reader-drawer pa-0 ma-0">
        <v-col class="pa-0 ma-0" cols="6" @click="clickTab(0)">
          <v-row
            class="reader-drawer-tab pa-0 ma-0"
            :class="{ selected: tabIndex == 0 }"
            align="center"
            justify="center"
            >目录</v-row
          ></v-col
        >
        <v-col class="pa-0 ma-0" cols="6" @click="clickTab(1)">
          <v-row
            class="reader-drawer-tab pa-0 ma-0"
            :class="{ selected: tabIndex == 1 }"
            align="center"
            justify="center"
          >
            <div class="reader-drawer-tab-note">
              笔记
              <div class="reader-drawer-new" v-show="isShowNew"></div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </transition>
</template>

<script>
import ReaderDrawerCatalog from "@/views/common/web/reader/components/reader-drawer/reader-drawer-catalog"; //目录
import ReaderDrawerNote from "@/views/common/web/reader/components/reader-drawer/reader-drawer-note"; //笔记
export default {
  name: "reader-drawer",
  inject: ["getNavigation", "getBookAvailable"],
  props: {
    bookInfo: {
      type: Object,
      default: null,
    },
    bookId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabIndex: 0,
      currentCatalogIndex: 0,
      lastCatalogIndex: 0,
      isFirst: true,
      isShowNew: false, //是否显示笔记的圆点
      navigation: null,
    };
  },
  components: {
    ReaderDrawerCatalog,
    ReaderDrawerNote,
  },
  computed: {},
  methods: {
    transitionComplete() {
      this.navigation = this.getNavigation();
    },
    clickTab(index) {
      this.tabIndex = index;
      if (index === 1) {
        this.$track.event({
          category: "reader",
          name: "enter_student_note_list",
          value: this.bookId.toString(),
        });
      }
    },
    showTeacherSay() {
      this.$emit("showTeacherSay");
    },
    jumpTo(index) {
      this.lastCatalogIndex = this.currentCatalogIndex;
      this.currentCatalogIndex = index;
      this.$set(
        this.navigation.toc[this.currentCatalogIndex],
        "isSelected",
        true
      );
      if (this.lastCatalogIndex !== this.currentCatalogIndex) {
        this.$set(
          this.navigation.toc[this.lastCatalogIndex],
          "isSelected",
          false
        );
      }
      this.$emit("jumpTo", index);
    },
  },
  watch: {
    navigation(newVal, oldVal) {
      if (newVal && newVal.toc && this.isFirst) {
        this.isFirst = false;
        this.$set(this.navigation.toc[0], "isSelected", true);
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/anim";
.content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 102;
  width: 85%;
  max-width: 400px;
  height: 100%;
  background: white;
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333333;
  }
  .reader-drawer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background: white;
    box-shadow: 0px 0px 12px 0px rgba(232, 232, 232, 0.7);
    z-index: 101;
    .reader-drawer-tab {
      position: relative;
      height: 56px;
      line-height: 16px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      text-align: center;
      &.selected {
        color: #4d9f7c;
      }
      .reader-drawer-tab-note {
        position: relative;
        .reader-drawer-new {
          position: absolute;
          bottom: 15px;
          right: -5px;
          width: 6px;
          height: 6px;
          background: #f20000;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
