<template>
  <v-bottom-sheet
    overlay-opacity="0"
    class="pa-0 ma-0"
    scrollable
    max-width="900px"
    v-model="visible"
  >
    <v-card class="pa-0  pb-6 card" min-height="264" max-height="500">
      <v-row
        justify="space-between"
        class="pl-5 pr-3 ma-0 mt-4"
        style="max-height:56px;"
      >
        <p class="pa-0 ma-0 my-auto common--text f18 font-weight-bold">
          拼音标注
        </p>
        <v-btn class="pa-0 ma-0 my-auto" color="minor" height="100%" icon
        @click="close"
          ><v-icon size="20">icon-error</v-icon></v-btn
        >
      </v-row>
      <v-row class="px-5 ma-0 mt-3 content" align-content="start">
        <ruby class="pr-2 mr-1 f20 common--text"  v-for="(p,i) in pinyinArray" :key="i">
          {{pinyinText[i]}} <rt class="f16"><rp>(</rp>{{p}}<rp>)</rp></rt>
      </ruby>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { getPinyinData } from "@/api/api-reader.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    pinyinText: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      pinyin: [],
    };
  },
  methods: {
    getPinyinText() {
      let text = this.pinyinText.replace(/(^\s*)|(\s*$)/g, "")
      console.log('text',text);
      getPinyinData(text)
        .then((res) => {
          this.pinyin = res.pinyin;
        })
        .catch(({ res }) => {
          if (!res || !res.message) {
            return;
          }
          this.showSnackbar({ msg: res.message });
        });
    },
    close(){
      this.visible = false
    }
  },
  watch:{
    dialog(val){
      if(val){
        this.getPinyinText()
      }else{
        this.pinyin = []
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    pinyinArray(){
      let tmp = _.flattenDeep(this.pinyin);
       _.forEach(tmp, (p,i) => {
        if (p == ""){
          tmp[i] = this.pinyinText[i]
        }
      });
      return tmp
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 16px 16px 0px 0px !important;
}

.content{
  overflow-x: hidden;
  overflow-y:auto;
  height:100%;
}
</style>
