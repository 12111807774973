<template>
  <div class="mx-4">
    <v-list class="content">
      <v-list-item v-for="{ user, note, id } in comments" :key="id">
        <p class="common--text f14">
          <span class="blueGray--text f14">{{ user.name }}：</span>
          {{ note }}
        </p>
      </v-list-item>
      <v-btn text @click="nextClickHandler" class="f14 blueGray--text"
        >{{
          btnTextControl
            ? "全部" + comments.length + "条笔记"
            : "做第一个写笔记的人吧"
        }}
        <v-icon class="ml-2" size="12">icon-return mdi-rotate-180</v-icon>
      </v-btn>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    comments: {
      type: Array,
      default: () => {},
    },
  },
  inject: ["showNoteComments", "showCommentTextField", "isTeacher"],
  methods: {
    nextClickHandler() {
      if (this.isTeacher && this.comments.length > 0) {
        this.showNoteComments();
        return;
      }

      if (this.comments.length > 0) {
        this.showNoteComments();
      } else {
        this.showCommentTextField();
      }
    },
  },
  computed: {
    commentsTwo() {
      return _.dropRight(this.comments, this.comments.length - 2);
    },
    btnTextControl() {
      return this.comments.length > 0 || this.isTeacher;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f5f6f9;
  border-radius: 8px;
}

.comment {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}
</style>
