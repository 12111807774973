<template>
  <v-col class="reader-drawer-catalog-container ma-0">
    <reader-drawer-catalog-book
      :bookInfo="bookInfo"
      :bookId="bookId"
      :chapterNum="getNavigation().toc.length"
      @showTeacherSay="showTeacherSay"
    ></reader-drawer-catalog-book>

    <v-col
      class="reader-drawer-catalog-list pa-0 ma-0"
      :style="{ height: chaptersHeight }"
    >
      <reader-drawer-catalog-item
        v-show="getNavigation().toc.length > 0"
        v-for="(item, index) in getNavigation().toc"
        :key="index"
        :item="item"
        :index="index"
        :showLine="index !== getNavigation().toc.length - 1"
        @jumpTo="jumpTo"
      ></reader-drawer-catalog-item>
    </v-col>

    <v-row
      class="pa-0 ma-0"
      justify="center"
      v-show="getNavigation().toc.length <= 0"
      ><div class="reader-drawer-catalog-empty">暂无章节目录</div></v-row
    >
  </v-col>
</template>

<script>
import ReaderDrawerCatalogBook from "@/views/common/web/reader/components/reader-drawer/reader-drawer-catalog-book";
import ReaderDrawerCatalogItem from "@/views/common/web/reader/components/reader-drawer/reader-drawer-catalog-item";
export default {
  name: "reader-drawer-catalog",
  inject: ["getNavigation"],
  props: {
    bookInfo: {
      type: Object,
      default: null,
    },
    bookId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      screenHeight: 0,
      chaptersHeight: "0px",
    };
  },
  components: {
    ReaderDrawerCatalogBook,
    ReaderDrawerCatalogItem,
  },
  computed: {},
  methods: {
    showTeacherSay() {
      this.$emit("showTeacherSay");
    },
    jumpTo(index) {
      this.$emit("jumpTo", index);
    },
  },
  watch: {},
  created() {
    //获取屏幕高度
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.chaptersHeight = this.screenHeight - 193 - 56 + "px";
  },
};
</script>

<style lang='scss' scoped>
.reader-drawer-catalog-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px 0px 56px 0px;
  .reader-drawer-catalog-list {
    overflow: auto;
  }
  .reader-drawer-catalog-empty {
    position: absolute;
    top: 0;
    height: 18px;
    line-height: 18px;
    padding: 30px 0px 0px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c9c9c9;
    text-align: center;
  }
}
</style>