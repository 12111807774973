<template>
  <div class="reader-bottom">
    <transition
      name="slide-up"
      enter-class="slide-up-progress-enter"
      leave-to-class="slide-up-progress-leave-to"
    >
      <v-row
        class="reader-bottom-progress pa-0 ma-0"
        :style="{ background: themeList[currentThemeIndex].barBackground }"
        v-show="ifProgressShow"
      >
        <v-row
          class="reader-bottom-progress-row pa-0 ma-0"
          align="center"
          justify="center"
        >
          <v-col
            class="reader-bottom-progress-left ma-0"
            cols="auto"
            @click="clickProgressLeft"
          >
            <v-row class="pa-0 ma-0">
              <v-icon size="16" :color="isNight ? itemNightColor : arrowColor"
                >icon-uniE939</v-icon
              >
            </v-row>
          </v-col>

          <v-col class="pa-0 ma-0">
            <v-row class="pa-0 ma-0" align="center" justify="center">
              <input
                ref="progress"
                class="reader-bottom-progress-line"
                type="range"
                max="100"
                min="0"
                step="1"
                @change="onProgressChange($event.target.value)"
                @input="onProgressInput($event.target.value)"
                :value="bottomProgress"
                :disabled="!getBookAvailable()"
              />
            </v-row>
          </v-col>

          <v-col
            class="reader-bottom-progress-right ma-0"
            cols="auto"
            @click="clickProgressRight"
          >
            <v-row class="pa-0 ma-0">
              <v-icon size="16" :color="isNight ? itemNightColor : arrowColor"
                >icon-right-arrow</v-icon
              >
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </transition>

    <transition
      name="slide-up"
      enter-class="slide-up-settings-enter"
      leave-to-class="slide-up-settings-leave-to"
    >
      <v-row
        class="reader-bottom-settings pa-0 ma-0"
        :style="{ background: themeList[currentThemeIndex].barBackground }"
        v-show="ifSettingShow"
      >
        <v-col class="pa-0 ma-0">
          <v-row class="reader-bottom-settings-font-size pa-0" align="center">
            <span
              class="reader-bottom-settings-text"
              :style="{ color: isNight ? itemNightColor : itemNormalColor }"
              >字号</span
            >
            <v-row class="px-5 py-0 ma-0" align="center" justify="center">
              <v-col
                class="reader-bottom-settings-font-size-zoom pa-0 ma-0"
                :style="{
                  color: isMin
                    ? itemDisableColor
                    : isNight
                    ? itemNightColor
                    : itemNormalColor,
                }"
                cols="auto"
                @click="setFontSize(currentFontSize - 2, false)"
              >
                A -
              </v-col>
              <v-col
                class="reader-bottom-settings-font-size-text"
                :style="{ color: isNight ? itemNightColor : itemNormalColor }"
              >
                {{ currentFontSize }}
              </v-col>
              <v-col
                class="reader-bottom-settings-font-size-zoom pa-0 ma-0"
                :style="{
                  color: isMax
                    ? itemDisableColor
                    : isNight
                    ? itemNightColor
                    : itemNormalColor,
                }"
                cols="auto"
                @click="setFontSize(currentFontSize + 2, false)"
              >
                A +
              </v-col>
            </v-row>
          </v-row>

          <v-row
            class="reader-bottom-settings-theme pa-0"
            align="center"
            justify="center"
          >
            <span
              class="reader-bottom-settings-text"
              :style="{ color: isNight ? itemNightColor : itemNormalColor }"
              >背景</span
            >
            <v-row class="px-5 py-0 ma-0" align="center" justify="center">
              <v-col
                class="reader-bottom-settings-theme-item pa-0 ma-0"
                cols="auto"
                :class="{ selected: currentThemeIndex === 0 }"
                :style="{ background: themeList[0].style.body.background }"
                @click="setTheme(0, false)"
              >
              </v-col>
              <v-col class="pa-0 ma-0"></v-col>
              <v-col
                class="reader-bottom-settings-theme-item pa-0 ma-0"
                cols="auto"
                :class="{ selected: currentThemeIndex === 1 }"
                :style="{ background: themeList[1].style.body.background }"
                @click="setTheme(1, false)"
              >
                <v-img
                  class="reader-bottom-settings-theme-item-eye"
                  src="~@/assets/img_eye.png"
                  width="26"
                  height="12"
                  aspect-ratio="1"
                ></v-img>
              </v-col>
              <v-col class="pa-0 ma-0"></v-col>
              <v-col
                class="reader-bottom-settings-theme-item pa-0 ma-0"
                cols="auto"
                :class="{ selected: currentThemeIndex === 2 }"
                :style="{ background: themeList[2].style.body.background }"
                @click="setTheme(2, false)"
              >
              </v-col>
              <v-col class="pa-0 ma-0"></v-col>
              <v-col
                class="reader-bottom-settings-theme-item pa-0 ma-0"
                cols="auto"
                :class="{ selected: currentThemeIndex === 3 }"
                :style="{ background: themeList[3].style.body.background }"
                @click="setTheme(3, false)"
              >
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </transition>

    <reader-drawer
      v-show="ifShowDrawer"
      :bookInfo="bookInfo"
      :bookId="bookId"
      @showTeacherSay="showTeacherSay"
      @jumpTo="jumpTo"
    ></reader-drawer>

    <transition name="fade">
      <div
        class="content-mask"
        v-show="ifShowDrawer"
        @click="hideContent"
      ></div>
    </transition>

    <transition name="slide-up">
      <v-row
        class="reader-bottom-menu pa-0 ma-0"
        :class="{
          'hide-box-shadow': ifSettingShow || ifProgressShow || !showBar,
        }"
        :style="{ background: themeList[currentThemeIndex].barBackground }"
        v-show="showBar"
      >
        <v-col class="pa-0 ma-0" cols="3" @click="showSetting(0)">
          <v-icon
            class="reader-bottom-icon pa-0"
            size="18"
            :color="isNight ? itemNightColor : itemNormalColor"
            >icon-catalog</v-icon
          >
          <div
            class="reader-bottom-text"
            :style="{ color: isNight ? itemNightColor : itemNormalColor }"
          >
            目录
          </div>
        </v-col>

        <v-col class="pa-0 ma-0" cols="3" @click="showSetting(1)">
          <v-icon
            class="reader-bottom-icon pa-0"
            size="18"
            :color="
              currentItemIndex === 1
                ? itemSelectedColor
                : isNight
                ? itemNightColor
                : itemNormalColor
            "
            >icon-speed</v-icon
          >
          <div
            class="reader-bottom-text"
            :style="{
              color:
                currentItemIndex === 1
                  ? itemSelectedColor
                  : isNight
                  ? itemNightColor
                  : itemNormalColor,
            }"
          >
            进度
          </div>
        </v-col>

        <v-col class="pa-0 ma-0" cols="3" @click="showSetting(2)">
          <v-icon
            class="reader-bottom-icon pa-0"
            size="18"
            :color="
              currentItemIndex === 2
                ? itemSelectedColor
                : isNight
                ? itemNightColor
                : itemNormalColor
            "
            >icon-typeface</v-icon
          >
          <div
            class="reader-bottom-text"
            :style="{
              color:
                currentItemIndex === 2
                  ? itemSelectedColor
                  : isNight
                  ? itemNightColor
                  : itemNormalColor,
            }"
          >
            设置
          </div>
        </v-col>

        <v-col class="pa-0 ma-0" cols="3" @click="showSetting(3)">
          <v-icon
            class="reader-bottom-icon pa-0"
            size="18"
            :color="
              currentItemIndex === 3
                ? itemSelectedColor
                : isNight
                ? itemNightColor
                : itemNormalColor
            "
            >icon-2</v-icon
          >
          <div
            class="reader-bottom-text"
            :style="{
              color:
                currentItemIndex === 3
                  ? itemSelectedColor
                  : isNight
                  ? itemNightColor
                  : itemNormalColor,
            }"
          >
            写想法
          </div>
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
import {
  postEPubStudentReadConfig,
  postEPubTeacherReadConfig,
} from "@/api/library";
import ReaderDrawer from "@/views/common/web/reader/components/reader-drawer/reader-drawer";
export default {
  name: "reader-bottom-bar",
  inject: [
    "getRendition",
    "getNavigation",
    "getLocations",
    "getBookAvailable",
    "getThemes",
    "isTeacher",
    "showNoteBtn",
  ], //由上层组件提供，不一定是上一层的父组件
  props: {
    bookInfo: {
      type: Object,
      default: null,
    },
    bookId: {
      type: Number,
      default: 0,
    },
    showBar: {
      type: Boolean,
      default: false,
    },
    progress: Number,
  },
  data() {
    return {
      currentItemIndex: 0,
      arrowColor: "#322F2B",
      itemSelectedColor: "#4D9F7C",
      itemNormalColor: "#444444",
      itemDisableColor: "#C4C4C4",
      isNight: false,
      isMin: false,
      isMax: false,
      itemNightColor: "white",
      ifSettingShow: false,
      ifProgressShow: false,
      bottomProgress: 0,
      ifShowDrawer: false,
      fontSizeList: [
        { fontSize: 14 },
        { fontSize: 16 },
        { fontSize: 18 },
        { fontSize: 20 },
      ],
      currentFontSize: 16,
      currentLineHeight: 34,
      currentThemeIndex: 0,
      themeList: [
        {
          name: "normal",
          barBackground: "white",
          style: {
            body: {
              color: "#444444",
              background: "#F4F4F4",
            },
          },
        },
        {
          name: "eyeCaring",
          barBackground: "#F3E7CF",
          style: {
            body: {
              color: "#444444",
              background: "#F7E7CF",
            },
          },
        },
        {
          name: "green",
          barBackground: "#DCF2DC",
          style: {
            body: {
              color: "#444444",
              background: "#CEEDD2",
            },
          },
        },
        {
          name: "dark",
          barBackground: "#2D2D2D",
          style: {
            body: {
              color: "#98989F",
              background: "#1D1D1D",
            },
          },
        },
      ],
    };
  },
  components: {
    ReaderDrawer,
  },
  computed: {},
  methods: {
    postConfig() {
      if (this.isTeacher) {
        postEPubTeacherReadConfig(
          this.themeList[this.currentThemeIndex].name,
          this.currentFontSize
        )
          .then((res) => {
            console.log(">>>>>postEPubTeacherReadConfig成功");
          })
          .catch(({ res, statusCode }) => {
            console.log(">>>>>postEPubTeacherReadConfig失败");
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      } else {
        postEPubStudentReadConfig(
          this.themeList[this.currentThemeIndex].name,
          this.currentFontSize
        )
          .then((res) => {
            console.log(">>>>>postEPubStudentReadConfig成功");
          })
          .catch(({ res, statusCode }) => {
            console.log(">>>>>postEPubStudentReadConfig失败");
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      }
    },
    showSetting(index) {
      this.currentItemIndex = index;
      if (index === 0) {
        this.$emit("hideBar");
        this.ifSettingShow = false;
        this.ifProgressShow = false;
        this.ifShowDrawer = true;
      } else if (index === 1) {
        this.ifSettingShow = false;
        this.ifProgressShow = true;
        this.$track.event({
          category: "reader",
          name: "client_process_menu",
        });
      } else if (index === 2) {
        this.ifSettingShow = true;
        this.ifProgressShow = false;
      } else {
        this.$emit("writeNote");
        this.ifSettingShow = false;
        this.ifProgressShow = false;
      }
    },
    hideSetting() {
      this.ifSettingShow = false;
      this.ifProgressShow = false;
    },
    setFontSize(fontSize, isFirst) {
      if (this.getThemes() && fontSize >= 14 && fontSize <= 60) {
        this.currentFontSize = fontSize;
        this.getThemes().fontSize(this.currentFontSize + "px");
        this.currentLineHeight = 30 + (fontSize - 14) * 2;
        this.getThemes().override("line-height", this.currentLineHeight + "px");
        this.$emit("getFontSize", this.currentFontSize, this.currentLineHeight);
        if (!isFirst) {
          this.postConfig();
        }
      }
    },
    setTheme(index, isFirst) {
      if (this.getThemes()) {
        this.currentThemeIndex = index;
        this.isNight = this.currentThemeIndex === 3;
        this.getThemes().default(this.themeList[this.currentThemeIndex].style);
        this.$emit("getTheme", this.themeList[this.currentThemeIndex]);
        if (!isFirst) {
          this.postConfig();
        }
      }
    },
    //拖动进度条时触发事件
    onProgressInput(progress) {
      this.bottomProgress = progress;
      this.$refs.progress.style.backgroundSize = `${this.bottomProgress}% 100%`;
    },
    //进度条松开后触发事件，根据进度条数值跳转到指定位置
    onProgressChange(progress) {
      if (this.getLocations() && progress >= 0 && progress <= 100) {
        this.bottomProgress = progress;
        this.setProgress(this.bottomProgress);
      }
    },
    clickProgressLeft() {
      if (
        this.getLocations() &&
        this.bottomProgress > 0 &&
        this.bottomProgress <= 100
      ) {
        this.bottomProgress = this.bottomProgress - 1;
        this.setProgress(this.bottomProgress);
      }
    },
    clickProgressRight() {
      if (
        this.getLocations() &&
        this.bottomProgress >= 0 &&
        this.bottomProgress < 100
      ) {
        this.bottomProgress = this.bottomProgress + 1;
        this.setProgress(this.bottomProgress);
      }
    },
    setProgress(progress) {
      const percentage = progress / 100;
      const location =
        percentage > 0 ? this.getLocations().cfiFromPercentage(percentage) : 0;
      this.getRendition().display(location);
      this.$track.event({
        category: "reader",
        name: "scroll_process",
        value: progress.toString(),
        params: {
          start_anchor: this.getRendition().location.start.cfi,
          end_anchor: this.getRendition().location.end.cfi,
        },
      });
    },
    showTeacherSay() {
      this.hideContent();
      this.$emit("showTeacherSay");
    },
    jumpTo(target) {
      this.$emit("jumpTo", target);
    },
    writeNote() {
      this.$emit("writeNote");
    },
    hideContent() {
      this.ifShowDrawer = false;
    },
  },
  watch: {
    showBar: {
      handler: function(val, oldVal) {
        if (!val) {
          this.currentItemIndex = 0;
        }
        this.showNoteBtn(val);
      },
      deep: true,
    },
    ifSettingShow(val) {
      if (val) {
        this.showNoteBtn(false);
      }
    },
    ifProgressShow(val) {
      if (val) {
        this.showNoteBtn(false);
      }
    },
    progress: {
      handler: function(val, oldVal) {
        this.bottomProgress = val;
        this.$refs.progress.style.backgroundSize = `${this.bottomProgress}% 100%`;
      },
      deep: true,
    },
    currentFontSize: {
      handler: function(val, oldVal) {
        if (val === 14) {
          this.isMin = true;
          this.isMax = false;
        } else if (val === 20) {
          this.isMin = false;
          this.isMax = true;
        } else {
          this.isMin = false;
          this.isMax = false;
        }
      },
      deep: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/anim";
.reader-bottom {
  .reader-bottom-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 58px;
    box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.15);
    z-index: 101;
    .reader-bottom-menu-text {
      height: 58px;
    }
    &.hide-box-shadow {
      box-shadow: none;
    }
    .reader-bottom-icon {
      display: flex;
      justify-content: center;
      margin: 10px 0 0 0;
    }
    .reader-bottom-text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3px;
      height: 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }

  .reader-bottom-progress {
    position: absolute;
    bottom: 58px;
    left: 0;
    width: 100%;
    height: 56px;
    box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.15);
    z-index: 101;
    .reader-bottom-progress-row {
      position: relative;
      width: 100%;
      height: 100%;
      .reader-bottom-progress-line {
        width: 100%;
        -webkit-appearance: none;
        height: 2px;
        background: -webkit-linear-gradient(#4d9f7c, #4d9f7c) no-repeat #c4c4c4;
        background-size: 0 100%;
        &:focus {
          outline: none;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background: white;
          border: 1px solid #ddd;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        }
      }
      .reader-bottom-progress-left {
        width: 46px;
        padding: 0 0 0 20px;
      }
      .reader-bottom-progress-right {
        width: 46px;
        padding: 0 0 0 10px;
      }
    }
  }

  .reader-bottom-settings {
    position: absolute;
    bottom: 58px;
    left: 0;
    width: 100%;
    height: 138px;
    box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.15);
    z-index: 101;
    .reader-bottom-settings-text {
      height: 18px;
      line-height: 18px;
      margin-left: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .reader-bottom-settings-font-size {
      position: relative;
      width: 100%;
      max-width: 1200px;
      height: 58px;
      margin: 10px auto 0;
      .reader-bottom-settings-font-size-zoom {
        width: 90px;
        height: 34px;
        border-radius: 19px;
        border: 1px solid #c4c4c4;
        line-height: 34px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
      }
      .reader-bottom-settings-font-size-text {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: center;
      }
    }
    .reader-bottom-settings-theme {
      position: relative;
      width: 100%;
      max-width: 1200px;
      height: 58px;
      margin: 6px auto 0;
    }
    .reader-bottom-settings-theme-item {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 19px;
      &.selected {
        border: 1px solid #4d9f7c;
      }
    }
    .reader-bottom-settings-theme-item-eye {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }

  .content-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.8);
  }
}
</style>
