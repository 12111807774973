<template>
  <v-bottom-sheet
    overlay-opacity="0"
    class="pa-0 ma-0"
    scrollable
    v-model="visible"
    persistent
  >
    <v-card height="58">
      <v-row class="pa-0 ma-0" justify="space-around" align="center">
        <v-avatar size="46" class="ml-2 cover" :class="rotateCss">
          <v-img
            max-width="40"
            max-height="40"
            width="40"
            aspect-ratio="1"
            rounded
            lazy-src="@/assets/share-img.jpg"
            :src="cover"
          ></v-img>
        </v-avatar>
        <v-btn icon @click="playPreClicked" :disabled="currentAudioIndex == 0"
          ><v-icon class="f16 common--text">icon-hy_back-off</v-icon></v-btn
        >
        <v-btn icon @click="playClicked"
          ><v-icon class="f22  common--text">{{ playIcon }}</v-icon></v-btn
        >
        <v-btn
          icon
          @click="playNextClicked"
          :disabled="currentAudioIndex == audioUrlArray.length - 1"
          ><v-icon class="f16 common--text">icon-hy_forward</v-icon></v-btn
        >
        <v-btn text class="f12 minor--text mt-n7 mr-n7" @click="close"
          >关闭</v-btn
        >
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { EpubCFI } from "epubjs";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: String,
      default: () => {},
    },
    bookId: {
      type: Number,
      default: 0,
    },
    audioData: {
      type: Array,
      default: () => {},
    },
  },
  inject: ["getRendition"],
  data() {
    return {
      rotateCss: "",
      currentAudioUrl: "",
      currentAudioIndex: 0,
      lastAudioIndex: null,
      audio: {},
      playIcon: "icon-hy_suspend",
    };
  },
  created() {},
  methods: {
    playClicked() {
      if (this.audio.paused) {
        this.audio.play();
        return;
      }

      if (this.audio.played) {
        this.playIcon = "icon-icon-hy_play";
        this.audio.pause();
        return;
      }

      this.audio.play();
    },
    playPreClicked() {
      let index = this.currentAudioIndex - 1;
      if (index >= 0 && index < this.audioUrlArray.length) {
        this.playWithIndex(index);
        const cfi = this.audioUrlArray[index].epubCfi;
        this.$track.event({
          category: "reader",
          name: "prev_audio",
          value: this.bookId,
          params: {
            start_anchor: cfi,
          },
        });
      }
    },
    playNextClicked() {
      let index = this.currentAudioIndex + 1;
      if (index >= 0 && index < this.audioUrlArray.length) {
        this.playWithIndex(index);
        const cfi = this.audioUrlArray[index].epubCfi;
        this.$track.event({
          category: "reader",
          name: "next_audio",
          value: this.bookId,
          params: {
            start_anchor: cfi,
          },
        });
      } else {
        return;
      }
    },
    getCurrentFirstUrl() {
      let startCfi = this.rendition.location.start.cfi;
      let audioArray = this.audioUrlArray.filter(({ epubCfi }) => {
        return (
          new EpubCFI().compare(startCfi, epubCfi) == 0 ||
          new EpubCFI().compare(startCfi, epubCfi) == -1
        );
      });

      let audioFirst = _.first(audioArray);
      this.currentAudioIndex = _.findIndex(this.audioUrlArray, audioFirst);
      var audio = document.getElementById("audio");
      audio.src = audioFirst.audioUrl;
      this.$track.event({
        category: "reader",
        name: "start_audio",
        value: this.bookId,
        params: {
          start_anchor: audioFirst.epubCfi,
        },
      });
    },
    createPlayer() {
      audio.onplaying = this.startedPlay;
      audio.onpause = this.pausedPlay;
      audio.onended = this.endedPlay;
      this.audio = audio;
    },
    startedPlay() {
      if (this.lastAudioIndex != null) {
        let lastCfi = this.audioUrlArray[this.lastAudioIndex].epubCfi;
        this.rendition.annotations.remove(lastCfi, "highlight");
      }
      this.lastAudioIndex = this.currentAudioIndex;

      let cfi = this.audioUrlArray[this.currentAudioIndex].epubCfi;
      if (cfi) {
        this.autoNextPage(cfi);
        this.rendition.annotations.add(
          "highlight",
          cfi,
          { type: "highlight" },
          (e) => {},
          undefined,
          { fill: "#4D9979", "fill-opacity": "0.3" }
        );
      }
      this.playIcon = "icon-hy_suspend";
      this.startRotate();
    },
    autoNextPage(cfi) {
      let endCfi = this.rendition.location.end.cfi;
      let isOutView = new EpubCFI().compare(cfi, endCfi);
      if (isOutView) {
        this.rendition.display(cfi);
      }
    },
    pausedPlay() {
      this.pauseRotate();
    },
    endedPlay() {
      this.pauseRotate();
      let cfi = this.audioUrlArray[this.currentAudioIndex].epubCfi;
      if (cfi) {
        this.rendition.annotations.remove(cfi, "highlight");
      }

      let index = this.currentAudioIndex + 1;
      this.playWithIndex(index);
    },
    playWithIndex(index) {
      this.currentAudioIndex = index;
      let url = this.audioUrlArray[this.currentAudioIndex].audioUrl;
      this.audio.src = url;
      this.audio.play();
    },
    startRotate() {
      this.rotateCss = "rotate play";
    },
    pauseRotate() {
      this.rotateCss = "rotate pause";
    },
    stopRotate() {
      this.rotateCss = "";
    },
    close() {
      let cfi = this.audioUrlArray[this.currentAudioIndex].epubCfi;
      if (cfi) {
        this.rendition.annotations.remove(cfi, "highlight");
        this.$track.event({
          category: "reader",
          name: "close_audio",
          value: this.bookId,
          params: {
            start_anchor: cfi,
          },
        });
      }
      this.stopRotate();
      this.audio.pause();
      this.visible = false;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCurrentFirstUrl();
        this.audio.play();
      }
    },
    audioUrlArray(val) {
      if (val.length > 0) {
        this.createPlayer();
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    rendition() {
      return this.getRendition();
    },
    audioUrl() {
      return this.currentAudioUrl;
    },
    audioUrlArray() {
      let tmp = _.filter(this.audioData, ({ audioUrl }) => {
        return audioUrl != "";
      });
      return tmp;
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  border-radius: 23px;
  border-width: 5px;
  border: solid;
  border-color: white;
  background: #ffffff;
  box-shadow: 0px 2px 7px 0px rgba(232, 232, 232, 0.7);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  transition: 0.5s;
  animation: rotate 10s linear infinite; /*开始动画后无限循环，用来控制rotate*/
  animation-play-state: paused;

  &.play {
    animation-play-state: running;
  }

  &.pause {
    animation-play-state: paused;
  }
}

::v-deep .v-image__image--preload {
  filter: blur(0px);
}
</style>
