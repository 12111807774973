<template>
  <v-list class="mt-12 reader-drawer-note-container" :key="listKey">
    <v-list-item v-for="note in notes" :key="note.id" class="mb-4">
      <div style="width:100%">
        <v-row class="ma-0 pa-0" v-if="note.note" @click="locateTo(note)">
          <v-col cols="auto" class="mt-2">
            <div class="line-one"></div>
          </v-col>
          <v-col class="px-0">
            <v-clamp class="common--text f16" autoresize :max-lines="2">
              {{ note.note }}</v-clamp
            >
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" v-if="note.markedText" @click="locateTo(note)">
          <p class="mt-3 ml-3 yellow-font f14">引</p>
          <v-col cols="auto mx-0">
            <div class="line-two"></div>
          </v-col>
          <v-col class="px-0 ma-0">
            <v-clamp class="yellow-font f14" autoresize :max-lines="2">{{
              note.markedText
            }}</v-clamp>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" justify="space-between">
          <p class="pa-0 ml-2 minor--text f12 my-auto">
            {{ note.createAtStr }}
          </p>
          <v-btn icon @click="removeHandler(note)">
            <v-img
              src="@/assets/icon-trash.png"
              sizes="16px"
              max-width="16px"
              max-height="16px"
            >
            </v-img>
          </v-btn>
        </v-row>
      </div>
    </v-list-item>
    <c-empty :isShow="notes == undefined || notes.length == 0"></c-empty>
  </v-list>
</template>

<script>
import VClamp from "vue-clamp";
import { deleteStudentNotes, deleteTeacherNotes } from "@/api/library.js";
import CEmpty from "@/components/c-empty";
export default {
  name: "reader-drawer-note",
  props: {},
  data() {
    return {
      listKey: 0,
    };
  },
  components: { VClamp, CEmpty },
  inject: ["getNotes", "getRendition", "hideDrawer", "setNotes", "isTeacher"],
  computed: {
    notes() {
      let tmp = _.forEach(this.getNotes(), (note) => {
        note.createAtStr = this.transforCreateAt(note.createdAt);
      });
      return tmp;
    },
    rendition() {
      return this.getRendition;
    },
  },
  methods: {
    locateTo(note) {
      if (note.id) {
        //埋点click_note
        this.$track.event({
          category: "reader",
          name: "click_note",
          value: note.id,
          params: {
            source_page: "student_note_page",
          },
        });
      }

      if (!note.epubcfi) {
        return;
      }

      this.hideDrawer();
      this.rendition().display(note.epubcfi);
    },
    removeHandler(note) {
      this.showDialog({
        text: "确定删除此条笔记?",
        icon: "error",
        //自定义按钮，按钮最多两项
        btns: [
          {
            text: "确定",
            cbk: () => {
              this.deleteNote(note);
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: "取消",
            cbk: () => {
              this.closeDialog();

              console.log("取消按钮回调");
            },
            color: "red",
          },
        ],
      });
    },
    deleteNote(note) {
      console.log("delnot:", note);
      this.rendition().annotations.remove(note.epubcfi, "underline");

      if (note.id != "") {
        const noteId = note.id;
        const bookId = note.bookId;
        if (this.isTeacher) {
          deleteTeacherNotes(this.bookId, noteId).then((res) => {});
        } else {
          deleteStudentNotes(this.bookId, noteId).then((res) => {});
        }

        var list = this.getNotes();
        _.remove(list, function({ id }) {
          return id == noteId;
        });

        this.setNotes(list);
        this.listKey++;
      }
    },
    transforCreateAt(timeDate) {
      var timestamp = new Date(parseInt(timeDate) * 1000);
      var timeStr = this.$moment(timestamp).format("MM-DD HH:mm");
      return timeStr;
    },
  },
  created() {
    this.$root.$on("noteRemoved", () => {
      this.listKey++;
    });
  },
};
</script>

<style lang="scss" scoped>
.reader-drawer-note-container {
  height: calc(100% - 88px);
  overflow: auto;
}

.line-one {
  width: 2px;
  height: 20px;
  background: #ed7b15;
  border-radius: 1px;
}

.line-two {
  width: 1px;
  height: 30px;
  background: #f7b500;
  border-radius: 1px;
}

.yellow-font {
  color: #ed7b15;
}
</style>
