<template>
  <v-col class="container pa-0 ma-0">
    <v-row class="book-content pa-0 ma-0">
      <v-col class="pa-0 ma-0" cols="auto">
        <c-img-book-cover
          class="book-img"
          :width="85"
          :height="113"
          :max-width="85"
          :max-height="113"
          :title="bookInfo.name"
          :src="bookInfo.cover"
        ></c-img-book-cover>
      </v-col>

      <v-col class="book-right ma-0">
        <v-clamp class="book-name pa-0 ma-0" autoresize :max-lines="2">{{
          bookInfo.name
        }}</v-clamp>
        <v-clamp class="book-author pa-0" autoresize :max-lines="1">{{
          bookInfo.writer
        }}</v-clamp>
        <div class="book-chapters">共{{ chapterNum }}章</div>
        <div class="book-button" @click="clickBtn()" v-show="!isTeacher">
          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-icon class="primary--text pa-0 ma-0" size="14px">icon-2</v-icon>
            <v-clamp
              class="book-button-text pa-0 ml-1 my-0"
              autoresize
              :max-lines="1"
              >老师说</v-clamp
            >
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row class="book-line pa-0"></v-row>
  </v-col>
</template>

<script>
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
import VClamp from "vue-clamp";
export default {
  name: "reader-drawer-catalog-book",
  inject: ["isTeacher"],
  props: {
    bookInfo: {
      type: Object,
      default: null,
    },
    bookId: {
      type: Number,
      default: 0,
    },
    chapterNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  components: {
    CImgBookCover,
    VClamp,
  },
  computed: {},
  methods: {
    clickBtn() {
      this.$emit("showTeacherSay");
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 193px;
  .book-content {
    width: 100%;
    height: 192px;
    .book-img {
      margin: 58px 0px 0px 24px;
    }
    .book-right {
      position: relative;
      padding: 68px 20px 0px;
      .book-name {
        line-height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
      }
      .book-author {
        line-height: 14px;
        margin: 10px 0px 0px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
      }
      .book-chapters {
        position: absolute;
        bottom: 20px;
        left: 20px;
        line-height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .book-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 72px;
        height: 25px;
        border-radius: 15px;
        border: 1px solid #5cb58f;
        .book-button-text {
          height: 23px;
          line-height: 23px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d9f7c;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }
  }
  .book-line {
    height: 1px;
    margin: 0px 10px;
    background: #f8f8f8;
  }
}
</style>
