import store from "@/store";
function userRole() {
    if (store.state.auth.jwtObject) {
        return store.state.auth.jwtObject.roleType == 1 ? 'student' : 'teacher'
    }
    else {
        return 'student'
    }
}

export function getPinyinData(q) {
    return request.v2.get(userRole() + "/text-to-pinyin", { params: { q } })
}

export function getBookAudioData(bookId) {
    return request.v2.get(userRole() + `/book/${bookId}/tts-audios`)
}

