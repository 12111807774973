import { render, staticRenderFns } from "./reader-drawer-catalog-item.vue?vue&type=template&id=1b5f9d78&scoped=true&"
import script from "./reader-drawer-catalog-item.vue?vue&type=script&lang=js&"
export * from "./reader-drawer-catalog-item.vue?vue&type=script&lang=js&"
import style0 from "./reader-drawer-catalog-item.vue?vue&type=style&index=0&id=1b5f9d78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5f9d78",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
