<template>
  <v-row
    class="c-empty-container pa-0 ma-0 white"
    align="center"
    justify="center"
    v-show="isShow"
  >
    <v-img
      src="~@/assets/img_empty.png"
      width="144"
      height="144"
      max-width="144"
      max-height="144"
      aspect-ratio="1"
    ></v-img>
  </v-row>
</template>

<script>
export default {
  name: "c-empty",
  props: {
    isShow: Boolean,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.c-empty-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>