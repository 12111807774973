<template>
  <v-dialog v-model="visible" fullscreen width="100%">
    <div class="bg" @click="hide">
      <div :style="{ height: statusBarHeight + 'px' }"></div>
      <v-list
        width="100%"
        min-width="100%"
        class="pa-0 mx-0 transparent py-4"
        :style="hasCommented ? '' : 'padding-bottom:118px !important'"
        @click="hide"
      >
        <v-list-item
          :ripple="false"
          class="pa-0 px-6 mx-auto my-4"
          v-for="({ user, praised, praisedAmt, note, id }, i) in comments"
          :key="id"
          @click="visible = false"
        >
          <v-list-item-avatar class="mt-0 pt-0 head">
            <v-img
              :src="user.headImg"
              max-width="30px"
              max-height="30px"
              width="30px"
              height="30px"
              aspect-ratio="1"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content
            @click.stop="contentClickHandler"
            class="content"
          >
            <v-row justify="space-between" class="pa-0 ma-0">
              <v-clamp
                autoresize
                :max-lines="1"
                class="f14 blueGray--text mx-4 my-2"
                >{{ user.name }}</v-clamp
              >
              <v-btn
                @click.stop="praiseClickHandler(comments[i])"
                text
                class="blueGray--text"
              >
                <v-icon size="16" :color="praised ? 'primary' : 'blueGray'"
                  >icon-Fabulous</v-icon
                >
                <p
                  class="my-auto ml-1"
                  :class="praised ? 'primary--text' : 'blueGray--text'"
                >
                  {{ praisedAmt }}
                </p>
              </v-btn>
            </v-row>
            <v-clamp autoresize class="f14 common--text ml-4 pr-6 my-2">{{
              note
            }}</v-clamp>
          </v-list-item-content>
        </v-list-item>
        <v-row justify="center" class="pt-4 white--text f14"
          >更多内容,敬请期待～</v-row
        >
      </v-list>
      <v-row v-if="!hasCommented" class="input-bg pa-0 ma-0" style="width:100%">
      </v-row>
      <v-btn
        v-if="!hasCommented"
        class="input-btn"
        color="white"
        @click="inputBtnClickHandler"
      >
        <v-row class="ma-0 pa-0">
          <v-col cols="auto" class="my-auto pa-0">
            <v-icon size="18" color="minor my-auto pa-0">icon-note</v-icon>
          </v-col>
          <v-col cols="auto my-auto ma-0">
            <p class="f14 minor--text my-auto">写下你的笔记</p>
          </v-col>
        </v-row>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import VClamp from "vue-clamp";
import {
  praiseStudentComment,
  teacherPraiseStudentComment,
} from "@/api/library";
export default {
  name: "reader-note-comments",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    annotation: {
      type: Object,
      default: () => {},
    },
  },
  inject: [
    "showCommentTextField",
    "getComments",
    "getStatusBarHeight",
    "isTeacher",
  ],
  data() {
    return {
      comments: [],
      statusBarHeight: 20,
    };
  },
  created() {
    this.statusBarHeight = this.getStatusBarHeight();
  },
  methods: {
    hide() {
      this.visible = false;
    },
    getCommentList() {
      this.comments = this.getComments();
    },
    contentClickHandler() {},
    praiseClickHandler(comment) {
      if (this.isTeacher) {
        teacherPraiseStudentComment(comment.id)
          .then(() => {
            comment.praised = 1;
            comment.praisedAmt++;
          })
          .catch(({ res, statusCode }) => {
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      } else {
        praiseStudentComment(comment.id)
          .then(() => {
            comment.praised = 1;
            comment.praisedAmt++;
          })
          .catch(({ res, statusCode }) => {
            if (!res || !res.message) {
              return;
            }

            this.showSnackbar({ msg: res.message });
          });
      }
    },
    inputBtnClickHandler() {
      this.showCommentTextField();
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCommentList();
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    ...mapGetters({
      userId: "auth/userId",
    }),
    //是否评论过
    hasCommented() {
      if (this.isTeacher) {
        return true;
      }

      let mycomments = _.filter(this.comments, ({ user }) => {
        return user.id == this.userId;
      });
      return mycomments.length > 0;
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style lang="scss" scoped>
::v-deep v-dialog {
  overflow: hidden;
}

.bg {
  background: rgba(64, 62, 61, 0.9);
  height: 100%;
  overflow: hidden;
}

::v-deep .v-list {
  height: 100%;
  overflow: auto;
}

::v-deep .v-list-item {
  max-width: 900px !important;
}

.head {
  position: absolute;
  top: 0;
}
.content {
  background: white;
  margin-left: 45px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.input-bg {
  position: absolute;
  bottom: 0;
  width: 375px;
  height: 112px;
  background: linear-gradient(180deg, rgba(64, 62, 61, 0.2) 0%, #403e3d 100%);
  // filter: blur(3px);
}

.input-btn {
  position: absolute;
  bottom: 50px;
  left: calc((100% - 303px) / 2);
  width: 303px;
  min-height: 42px;
  background: #fcfcfc;
  border-radius: 22px;
}
</style>
