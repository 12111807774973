<template>
  <v-col class="pa-0 ma-0" @click="jumpTo()">
    <v-clamp
      class="reader-drawer-catalog-item-text ma-0"
      :class="{ selected: item.isSelected }"
      autoresize
      :max-lines="1"
    >
      {{ item.label.trim() }}
    </v-clamp>
    <v-row
      v-show="showLine"
      class="reader-drawer-catalog-item-line pa-0"
    ></v-row>
  </v-col>
</template>

<script>
import VClamp from "vue-clamp";
export default {
  name: "reader-drawer-catalog-item",
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  components: {
    VClamp,
  },
  computed: {},
  methods: {
    jumpTo() {
      this.$emit("jumpTo", this.index);
    },
  },
  watch: {},
  created() {},
};
</script>

<style lang='scss' scoped>
.reader-drawer-catalog-item-text {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0px 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  &.selected {
    color: #4d9f7c;
  }
}
.reader-drawer-catalog-item-line {
  height: 1px;
  margin: 0px 10px;
  background: #f8f8f8;
}
</style>